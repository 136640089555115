import 'css/src/components/common/public-pages/PublicPageHeader';

import React, { useEffect, useRef } from 'react';

import { Urls } from 'js/src/client';
import { PrimaryButton, SecondaryButton } from 'js/src/components/common/ui/Button';
import ContentfulTools from 'js/src/libs/contentful-tools';


const CREATE_HEADER_TYPE = 'site navbar header';

type Props = {
    backgroundColor?: string,
    invertTextColor?: boolean,
}

const PublicPageHeader = React.memo(function PublicPageHeader({
    backgroundColor = undefined,
    invertTextColor = false,
}: Props) {
    const navBarRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        const scrollHandler = (e: Event) => {
            const currentTarget = e.currentTarget;
            if (!(currentTarget instanceof Window)) {
                return;
            }
            if (!navBarRef.current) {
                return;
            }
            if (currentTarget.scrollY >= 110) {
                navBarRef.current.classList.add('compressed');
            }
            else {
                navBarRef.current.classList.remove('compressed');
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    });

    const style: Record<any, any> = {};
    if (backgroundColor) {
        style['backgroundColor'] = backgroundColor;
    }

    let textColorClass = 'text-dark';
    let logoImageSrc = 'https://storage.googleapis.com/lumen5-site-images/L5-logo/2024/Lumen5-PrimaryLogo.svg';
    let navbarShadeClass = 'navbar-light';


    if (invertTextColor) {
        textColorClass = 'fw-light';
        logoImageSrc = 'https://storage.googleapis.com/lumen5-site-images/L5-logo/2024/Lumen5-Reverse-White.png';
        navbarShadeClass = 'navbar-dark';
    }

    const createHeaderLinks = ContentfulTools.getPagesOfType(CREATE_HEADER_TYPE);
    let createNode = null;

    if (createHeaderLinks.length > 0) {
        const columnCount = Math.floor(createHeaderLinks.length / 7);
        createNode = (
            <li className="nav-item dropdown me-l5-lg-8">
                <a className={`nav-link text-decoration-none font-l5 font-l5--regular ${textColorClass}`} href="#" id="create-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Features
                    <i className="material-symbols-outlined align-center expand_more">expand_more</i>
                </a>
                <div className="dropdown-menu" aria-labelledby="create-dropdown" style={{ columnCount }}>
                    {createHeaderLinks.map((link: any, index: number) => {
                        return (
                            <a key={index} className={`dropdown-item font-l5`} href={link.url}>{link.text}</a>
                        );
                    })}
                </div>
            </li>
        );
    }


    let userOptionNode = null;
    if (SERVER.USER) {
        userOptionNode = (
            <li className="nav-item dropdown">
                <a
                    className={`nav-link text-decoration-none font-l5 font-l5--regular ${textColorClass}`}
                    href="#"
                    id="user-nav-options"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="material-symbols-outlined rounded-circle border">
                        person
                    </i>
                    <span className="d-lg-none d-xl-inline-block ms-l5-4 align-middle">
                        {SERVER.USER.first_name || SERVER.USER.email}
                    </span>
                    <i className="material-symbols-outlined align-center expand_more">
                        expand_more
                    </i>
                </a>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="user-nav-options">
                    <a className="dropdown-item" href={Urls.DASHBOARD}>Dashboard</a>
                    <a className="dropdown-item" href={Urls.ACCOUNT}>Account settings</a>
                    <a className="dropdown-item" href={Urls.LOGOUT}>Logout</a>
                </div>
            </li>
        );
    }
    else {
        userOptionNode = (
            <>
                <li className="nav-item me-l5-lg-8">
                    <SecondaryButton
                        href={Urls.LOGIN}
                        text="Log in"
                    />
                </li>
                <li className="nav-item">
                    <PrimaryButton
                        href={Urls.SIGNUP}
                        text={'Sign' + '\xa0' + 'up'}
                    />
                </li>
            </>
        );
    }

    return (
        <nav className={`navbar navbar-expand-lg fixed-top ${navbarShadeClass} header-container px-l5-lg-48 pt-l5-32`} style={style} ref={navBarRef}>
            <div className="d-flex ps-l5-lg-24 ms-l5-lg-48">
                <a href={Urls.HOME} className="navbar-brand p-l5-0 d-flex align-items-center">
                    <img className="rounded float-start nav-bar__logo"
                        src={logoImageSrc} alt="Lumen5 Logo" />
                </a>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsedBarId" aria-controls="collapsedBarId" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse me-l5-lg-48 justify-content-end" id="collapsedBarId">
                <div className="d-flex">
                    <ul className="navbar-nav">
                        <li className="nav-item me-l5-lg-8">
                            <a className={`nav-link text-decoration-none ${textColorClass} font-l5 font-l5--regular`} href={Urls.PRICING}>Pricing</a>
                        </li>
                        {createNode}
                        <li className="nav-item me-l5-lg-8 dropdown">
                            <a className={`nav-link  text-decoration-none font-l5 font-l5--regular ${textColorClass}`} href="#" id="enterprise-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Enterprise
                                <i className="material-symbols-outlined align-center expand_more">
                                    expand_more
                                </i>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="enterprise-dropdown">
                                <a className="dropdown-item" href="/enterprise">Lumen5 for enterprise</a>
                                <a className="dropdown-item" href="https://content.lumen5.com/case-studies" target="_blank" rel="noopener noreferrer">Case studies</a>
                                <a className="dropdown-item" href="https://content.lumen5.com/request-demo" target="_blank" rel="noopener noreferrer">Request a demo</a>
                            </div>
                        </li>
                        <li className="nav-item me-l5-lg-8 dropdown">
                            <a className={`nav-link  text-decoration-none font-l5 font-l5--regular ${textColorClass}`} href="#" id="learn-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Learn
                                <i className="material-symbols-outlined align-center expand_more">
                                    expand_more
                                </i>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="learn-dropdown">
                                <a className="dropdown-item" href="/learn/">Blog</a>
                                <a className="dropdown-item" href="https://pod.co/supercharge-marketing" target="_blank" rel="noopener noreferrer">Podcast</a>
                                <a className="dropdown-item" href="/learn/resources">Resources</a>
                            </div>
                        </li>
                        {userOptionNode}
                    </ul>
                </div>
            </div>
        </nav>
    );
});

export default PublicPageHeader;
