const data_tag = document.getElementById('contentful-page-listings');
let PAGE_DATA: any[] = [];
if (data_tag) {
    PAGE_DATA = JSON.parse(data_tag.innerText);
}

class ContentfulTools {
    static getUrl(url: string, opts: Record<string, number> = {}) {
        /*
        Contenful lets you set a bunch of settings on an image at loading time:

        https://www.contentful.com/developers/docs/references/images-api/

        This function will add query params onto the url passed in, based on the opts argument:
            - height (int)
            - width (int)
        */
        const querystringDict: Record<string, number> = {};
        if (opts['height']) {
            querystringDict['h'] = opts['height'];
        }

        if (opts['width']) {
            querystringDict['w'] = opts['width'];
        }

        const queryString = Object.keys(querystringDict).map(key => {
            return `${key}=${encodeURIComponent(querystringDict[key])}`;
        }).join('&');

        let finalUrl = url;
        if (!finalUrl.startsWith('http')) {
            finalUrl = `https:${url}`;
        }

        if (queryString) {
            finalUrl += `?${queryString}`;
        }
        return finalUrl;
    }

    static getPagesOfType(pageType: string) {
        let pageListings = [];
        for (let i = 0; i < PAGE_DATA.length; i++) {
            if (PAGE_DATA[i].area_used === pageType) {
                pageListings = PAGE_DATA[i].pages || [];
            }
        }
        return pageListings.map((pageListing: any) => {
            return {
                url: `/${pageListing.slug}`,
                text: pageListing.link_text,
            };
        });
    }
}

export default ContentfulTools;
