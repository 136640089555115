import 'css/src/components/common/ui/HiddenContainer';

import React from 'react';

type Props = {
    isHidden?: boolean,
    children?: React.ReactNode,
    ['data-testid']?: string,
}
const HiddenContainer = ({ isHidden = false, children, 'data-testid': dataTestId }: Props) => {
    return (
        <div className={`hidden-container ${isHidden ? 'invisible' : ''}`} data-testid={dataTestId}>
            {children}
        </div>
    );
};

const MemoizedHiddenContainer = React.memo(HiddenContainer);
export default MemoizedHiddenContainer;
export { HiddenContainer };
