import 'css/src/components/common/ui/Backdrop';

import React from 'react';
import classNames from 'classnames';

import Spinner from 'js/src/components/common/ui/Spinner';

export enum Z_INDEX_ORDER_CLASSNAME {
    content = 'z-content',
    slide_overlay = 'z-slide-overlay',
    dashboard_backdrop = 'z-dashboard-backdrop',
    transcript_backdrop = 'z-transcript-backdrop'
}

type BaseBackdropProps = {
    show?: boolean,
    onClick?: VoidFunction,
    className?: string,
    zIndexOrder?: Z_INDEX_ORDER_CLASSNAME,
    children?: React.ReactNode,
};

/**
 * A backdrop that takes up the entire height/width of its parent component.
 * - Its contents can be one of the exported variations below, or custom.
 * - This is a controlled component - its visibility is toggled by its parent.
 * - If the current stacking context has any elements with a z-index > 0, override the z-index
 *      of this component .common-ui-backdrop.backdrop-visible { z-index: $greatest-local-z-index }
 */
function BaseBackdrop({
    show = false,
    onClick,
    className = '',
    zIndexOrder = Z_INDEX_ORDER_CLASSNAME.content,
    children,
}: BaseBackdropProps) {
    const hasClickHandler = typeof onClick === 'function';
    const finalClassname = classNames('common-ui-backdrop', className, {
        'backdrop-visible': show,
        'backdrop-hidden': !show,
        'clickable': hasClickHandler && show,
        'not-clickable': !hasClickHandler || !show,
    }, zIndexOrder);

    return show ? (
        <div className={finalClassname} onClick={onClick}>
            {children}
        </div>
    ) : null;
}
const Backdrop = React.memo(BaseBackdrop);

function BaseLoadingBackdrop(props: BaseBackdropProps) {
    return (
        <BaseBackdrop {...props}>
            <div className="content-loading">
                <Spinner className='text-white' />
            </div>
        </BaseBackdrop>
    );
}
const LoadingBackdrop = React.memo(BaseLoadingBackdrop);

type BaseBlurBackdropProps = {
    children?: React.ReactNode,
} & BaseBackdropProps;

function BaseBlurBackdrop(props: BaseBlurBackdropProps) {
    const propClone = { ...props };
    delete propClone.children;

    return (
        <BaseBackdrop {...propClone}>
            {
                // only render the blur if the backdrop is visible for performance reasons
                props.show && (
                    <div className="content-blur" />
                )
            }
            {props.children}
        </BaseBackdrop>
    );
}

const BlurBackdrop: React.FC<BaseBlurBackdropProps> = React.memo(BaseBlurBackdrop);

export {
    Backdrop,
    BlurBackdrop,
    LoadingBackdrop
};
