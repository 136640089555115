import 'css/src/components/common/CookieBanner.scss';

import React, { Fragment, useCallback } from 'react';

import { CookieModal } from './CookieModal';

import { Urls } from 'js/src/client';
import Button, { BUTTON_COLORS, BUTTON_PADDING, BUTTON_VARIANTS } from 'js/src/components/common/ui/Button';
import HiddenContainer from 'js/src/components/common/ui/HiddenContainer';
import { COOKIE_KEYS } from 'js/src/libs/consts';
import type { CookieConsent } from 'js/src/libs/cookie-consent';
import Cookies from 'js-cookie';

export const CookieBanner = () => {
    const [isModalShown, setIsModalShown] = React.useState(false);
    const [hasUserConsented, setHasUserConsented] = React.useState(false);
    const onShowModal = () => setIsModalShown(true);
    const onCloseModal = () => setIsModalShown(false);
    let hasUserPreviouslyConsented = false;
    const cookieConsent = Cookies.get(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT);

    const onAcceptAll = useCallback(() => {
        const cookieConsent: CookieConsent = { storedPerformanceConsent: true, storedMarketingConsent: true };
        Cookies.set(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT, JSON.stringify(cookieConsent), { expires: 365 });
        setHasUserConsented(true);
        window.parsed_cookie_consent_values = cookieConsent;
    }, []);
    const onOnlyNecessary = useCallback(() => {
        const cookieConsent: CookieConsent = { storedPerformanceConsent: false, storedMarketingConsent: false };
        Cookies.set(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT, JSON.stringify(cookieConsent), { expires: 365 });
        setHasUserConsented(true);
        window.parsed_cookie_consent_values = cookieConsent;
    }, []);

    if (!cookieConsent) {
        return null;
    }
    const { storedPerformanceConsent, storedMarketingConsent } = JSON.parse(cookieConsent) as CookieConsent;
    hasUserPreviouslyConsented = typeof storedPerformanceConsent === 'boolean' && typeof storedMarketingConsent === 'boolean';

    const isUserInPrivacySettingsPage = window.location.pathname as Urls === Urls.PRIVACY_SETTINGS;
    const isBannerVisible = (!isModalShown && !hasUserConsented && !hasUserPreviouslyConsented) && !isUserInPrivacySettingsPage;

    return (
        <Fragment>
            <HiddenContainer data-testid="cookie-banner" isHidden={!isBannerVisible}>
                <div className="cookie-banner position-fixed bottom-0 w-100 d-flex align-items-center justify-content-between px-l5-32 py-l5-24">
                    <div className='cookie-banner-details-container w-100 d-flex align-items-center justify-content-between'>
                        <div className='cookie-banner__info color-white w-100'>
                            <div className='fw-semibold pb-l5-4'>This website uses cookies </div>
                            <div>
                                We use cookies to enhance your experience, analyze site performance, and support our service.  For more details, read our <a className='text-decoration-underline cookie-banner__info__link' href={Urls.PRIVACY} target='_blank' rel="noreferrer">Privacy Policy</a>.
                            </div>
                        </div>
                        <div className='cookie-banner-buttons'>
                            <Button
                                className='me-l5-32 text-white'
                                text='Cookie settings'
                                variant={BUTTON_VARIANTS.LINK}
                                padding={BUTTON_PADDING.NONE}
                                onClick={onShowModal}
                            />
                            <Button
                                className='me-l5-16'
                                text='Only necessary'
                                color={BUTTON_COLORS.TERTIARY}
                                onClick={onOnlyNecessary}
                            />
                            <Button
                                text='Accept all'
                                color={BUTTON_COLORS.TERTIARY}
                                onClick={onAcceptAll}
                                data-testid='accept-all-cookies'
                            />
                        </div>
                    </div>
                </div>
            </HiddenContainer>
            <CookieModal
                showing={isModalShown}
                onCloseModal={onCloseModal}
                storedPerformanceConsent={storedPerformanceConsent}
                storedMarketingConsent={storedMarketingConsent}
            />
        </Fragment>
    );
};
