import type { AnyMetric } from 'js/src/profiling/metrics';
import prom from 'promjs';
import type { Registry } from 'promjs/registry';
import uaParser from 'ua-parser-js';

const SCRAPE_INTERVAL_MS = 30000;
const MAX_NETWORK_RETRY_ATTEMPTS = 4;
const PRODUCTION_PUSH_GATEWAY_PATH = 'https://fpm.lumen5.com/metrics/';

const VALID_COMMON_LABELS = {
    browser: [
        'chrome',
        'firefox',
        'ie',
        'safari',
        'edge',
        'opera',
    ],
    context: [
        'node',
        'client-browser',
        'cloud-browser',
    ],
    os: [
        'mac os',
        'windows',
        'linux',
    ],
};

const LINUX_OS_TYPES = [
    'ubuntu',
    'mint',
    'centoS',
    'mageia',
    'arch',
    'debian',
    'fedora',
    'pclinuxos',
    'linux',
];

/**
 * Returns a key value pairing of all labels that should be included
 * in every metric sent. The method also accounts for conditional labels
 * based on the execution context (i.e. node vs browser)
 *
 * @returns {object}
 */
const globalLabelSet = (function() {
    const labels: Record<string, any> = {};
    const context = typeof window !== 'undefined' ? 'client-browser' : 'node';

    labels['context'] = context;
    labels['browser'] = '';
    labels['os'] = '';

    if (labels['context'] !== 'node') {
        const { browser, os } = uaParser(window.navigator.userAgent);

        const browserName = browser.name ? browser.name.toLowerCase() : '';
        labels['browser'] = VALID_COMMON_LABELS.browser.includes(browserName)
            ? browserName
            : 'other';

        const osName = os.name ? os.name.toLowerCase() : '';
        if (VALID_COMMON_LABELS.os.includes(osName)) {
            labels['os'] = osName;
        }
        else if (LINUX_OS_TYPES.includes(osName)) {
            labels['os'] = 'Linux';
        }
        else {
            labels['os'] = 'other';
        }
    }

    return labels;
})();

/**
 * Singleton implementation of a promjs wrapper used to send metrics to Prometheus via an aggregation gateway
 *
 * This class handles scheduling sending metrics to the gateway. The caller's responsibility is only
 * to register metrics and make observations.
 *
 */
class PrometheusBatcher {
    static instance: PrometheusBatcher;

    commonLabels: Record<string, string>;
    doPushMetrics: boolean;
    saveAttempt: number;
    pushIntervalId: number | undefined;
    interval: number;
    _registry: Registry | undefined;

    static getInstance() {
        if (PrometheusBatcher.instance) {
            return PrometheusBatcher.instance;
        }
        PrometheusBatcher.instance = new PrometheusBatcher();
        return PrometheusBatcher.instance;
    }

    constructor() {
        // global promjs instance
        this.registry = prom();

        // store global value set
        this.commonLabels = { ...globalLabelSet };

        // Push metrics at a regular interval only if operating in the browser in production
        // This is a temporary limitation until node profiling is consolidated
        const isProduction = process.env.NODE_ENV === 'production';
        const isBrowser = typeof window !== 'undefined';

        this.doPushMetrics = isProduction && isBrowser;
        this.pushIntervalId;
        this.saveAttempt = 1;
        this.interval = SCRAPE_INTERVAL_MS * this.saveAttempt;

        if (this.doPushMetrics) {
            this.pushIntervalId = setInterval(() => {
                this.resetAndSendMetrics();
            }, this.interval) as unknown as number;
        }
    }

    set registry(registry: Registry) {
        this._registry = registry;
    }

    get registry() {
        if (!this._registry) throw new Error('Registry not initialized');
        return this._registry;
    }

    /**
     * Overrides the context label that was automatically determined
     */
    overrideContext(newContext: string) {
        if (!VALID_COMMON_LABELS.context.includes(newContext)) {
            // eslint-disable-next-line no-console
            console.warn(`ActivityProfile received a non-valid context: ${newContext}`);
        }
        this.commonLabels = {
            ...this.commonLabels,
            context: newContext,
        };
    }

    /**
     * Creates a metric in the Prometheus registry
     */
    register(metricDefn: Record<string, any>) {
        const { name, type, description } = metricDefn;
        let histogramBuckets;
        if (type === 'histogram') {
            histogramBuckets = metricDefn.histogramBuckets;
        }
        const metricArgs = [type, name, description, histogramBuckets] as const;
        this.registry.create(...metricArgs);
    }

    /**
     * Resets the interval used to regularly send metrics to the gateway. Does not set
     * a new interval if the profiler has exceeded the max number network failures.
     */
    updatePushInterval() {
        if (!this.doPushMetrics) {
            return;
        }

        clearInterval(this.pushIntervalId);
        if (this.saveAttempt < MAX_NETWORK_RETRY_ATTEMPTS) {
            this.pushIntervalId = setInterval(() => {
                this.resetAndSendMetrics();
            }, this.interval) as unknown as number;
        }
    }

    /**
     * Resets each metric class and posts the information to the prom aggregation gateway
     */
    resetAndSendMetrics() {
        if (this.saveAttempt >= MAX_NETWORK_RETRY_ATTEMPTS) {
            return;
        }

        // Note: if testing locally, use http://localhost:7777/metrics/
        fetch(PRODUCTION_PUSH_GATEWAY_PATH, {
            method: 'POST',
            body: this.registry.metrics(),
        })
            .then(() => {
                this.registry.reset();
                this.saveAttempt = 1;
            })
            .catch((e: unknown) => {
                this.saveAttempt++;
                this.interval = SCRAPE_INTERVAL_MS * this.saveAttempt;
                this.updatePushInterval();
            });
    }

    observe(metricDefn: AnyMetric, measuredTime: number, eventLabels: Record<string, string>) {
        if (metricDefn.type !== 'histogram') {
            throw new Error('Only histograms can observe a time');
        }
        const fullLabelSet = { ...this.commonLabels, ...eventLabels };
        const metricClass = this.registry.get(metricDefn.type, metricDefn.name);
        if (!metricClass) throw new Error(`Metric ${metricDefn.name} not found`);
        metricClass.observe(measuredTime, fullLabelSet);
    }

    inc(metricDefn: AnyMetric, eventLabels: Record<string, string>, incValue = 1) {
        if (metricDefn.type !== 'counter') {
            throw new Error('Only counters can increment');
        }
        const fullLabelSet = { ...this.commonLabels, ...eventLabels };
        const metricClass = this.registry.get(metricDefn.type, metricDefn.name);
        if (!metricClass) throw new Error(`Metric ${metricDefn.name} not found`);
        metricClass.add(incValue, fullLabelSet);
    }
}

/**
 * Singleton implementation to export and create only one instance that
 * is attached to the global context
 */
function getGlobal() : Window {
    if (typeof window !== 'undefined') {
        return window;
    }
    if (typeof self !== 'undefined') {
        return self;
    }
    throw new Error('unable to locate global object');
}

let prometheusBatcherInstance: PrometheusBatcher;

if (getGlobal().Prometheus) {
    prometheusBatcherInstance = getGlobal().Prometheus;
}
else {
    prometheusBatcherInstance = PrometheusBatcher.getInstance();
    getGlobal().Prometheus = prometheusBatcherInstance;
}

export default prometheusBatcherInstance;
