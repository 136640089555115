import React from 'react';

import { CookieBanner } from '../cookie-policy/CookieBanner';

import Footer from 'js/src/components/common/public-pages/PublicPageFooter';
import Header from 'js/src/components/common/public-pages/PublicPageHeader';
import PageErrorBoundary from 'js/src/components/common/ui/ErrorBoundary';
import { initCookieConsent } from 'js/src/libs/cookie-consent';

type Props = {
    children?: React.ReactNode,
    headerBackgroundColor?: string,
    invertHeaderContentColor?: boolean,
}

const PublicPagesTrim = React.memo(function PublicPagesTrim({
    children,
    headerBackgroundColor = undefined,
    invertHeaderContentColor = false,
}: Props) {
    initCookieConsent();
    return (
        <>
            <PageErrorBoundary>
                <Header backgroundColor={headerBackgroundColor}
                    invertTextColor={invertHeaderContentColor}/>
                {children}
                <Footer />
                <CookieBanner />
            </PageErrorBoundary>
        </>
    );
});

export default PublicPagesTrim;
