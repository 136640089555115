import 'rc-switch/assets/index.css';

import React from 'react';
import classNames from 'classnames';

import type { SwitchChangeEventHandler } from 'rc-switch';
import Switch from 'rc-switch';

export type SwitchToggleChangeEventHandler = SwitchChangeEventHandler;
type Props = {
    className?: string,
    checked?: boolean,
    defaultChecked?: boolean,
    disabled?: boolean,
    onChange?: SwitchToggleChangeEventHandler,
}

const SwitchToggle = React.memo(function SwitchToggle({
    className = '',
    checked = false,
    defaultChecked = false,
    disabled = false,
    onChange,
}: Props) {
    return (
        <Switch
            className={classNames(className)}
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            onChange={onChange}
        />
    );
});

export default SwitchToggle;
