import 'css/src/components/common/public-pages/PublicPageFooter';

import React from 'react';

import { Urls } from 'js/src/client';
import ContentfulTools from 'js/src/libs/contentful-tools';


const VIDEO_CREATORS_FOOTER_TYPE = 'video creators footer';
const TOOLS_FOOTER_TYPE = 'tools footer';

const PublicPageFooter = React.memo(function PublicPageFooter() {
    const links = [
        {
            header: 'EXPLORE',
            links: [
                { url: Urls.ABOUT, text: 'About' },
                { url: Urls.CAREERS, text: 'Careers' },
                { url: 'https://ca.linkedin.com/company/lumen5', text: 'LinkedIn', target: '_blank' },
                { url: 'https://help.lumen5.com/en/', text: 'Support', target: '_blank' },
                { url: 'https://updates.lumen5.com/', text: 'Product updates', target: '_blank' },
            ],
        },
    ];


    const toolsList = ContentfulTools.getPagesOfType(TOOLS_FOOTER_TYPE);
    if (toolsList.length > 0) {
        toolsList.push({
            url: '/tools/',
            text: 'See\xa0all', // this is a non-breaking space
        });
        links.push({
            header: 'VIDEO TOOLS',
            links: toolsList,
        });
    }

    const creatorsList = ContentfulTools.getPagesOfType(VIDEO_CREATORS_FOOTER_TYPE);
    if (creatorsList.length > 0) {
        creatorsList.push({
            'url': '/video-maker/',
            text: 'See\xa0all', // this is a non-breaking space
        });
        links.push({
            header: 'VIDEO CREATORS',
            links: creatorsList,
        });
    }

    return (
        <footer className="footer-container">
            <div className="section-footer">
                <div className="">
                    <div className="d-flex flex-column flex-md-row justify-content-between py-l5-48 mx-l5-24">
                        {/* section 9.1 */}
                        <div className="logo-box">
                            <div className="ms-l5-md-48 ps-l5-md-48">
                                <img className="mb-l5-16 py-l5-8 logo-image"
                                    src="https://storage.googleapis.com/lumen5-site-images/L5-logo/2024/Lumen5-Reverse-White.png"/>
                                <p className="mt-l5-8 font-l5 font-l5--small">
                                    Lumen5 combines powerful AI with a simple drag-and-drop interface to help you create professional video content in minutes.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row me-l5-md-48 pe-l5-md-48">
                            {
                                links.map((item, index) => {
                                    return (
                                        <div className={`flex-fill mx-l5-md-48 mx-l5-16 mb-l5-48`} key={index}>
                                            {/* This is done so that we can hide the footer columns in mobile devices ( sm devices ) except `Explore` column while keeping the column layout consistent. */}
                                            <div className={`${item.header !== 'EXPLORE' ? 'd-none d-md-flex' : 'd-flex'} flex-column`}>
                                                <span className="font-l5 font-l5--small mb-l5-16 py-l5-8 footer-header">{item.header}</span>
                                                <ul className="footer-links">
                                                    {
                                                        item.links.map((link, innerIndex) => {
                                                            return (
                                                                <li className="my-l5-16" key={`${index}-${innerIndex}`}>
                                                                    <a href={link.url} className="font-l5 font-l5--small mt-l5-8 text-decoration-none" target={link.target}>{link.text}</a>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright py-l5-24">
                <div className="mx-l5-48">
                    <div className="px-l5-16 row">
                        <div className="col-sm-12">
                            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
                                <span className="my-l5-0 font-l5 font-l5--small flex-grow-1">Copyright © {((new Date()).getFullYear()).toString()} Lumen5</span>
                                <span className="my-l5-0 font-l5 font-l5--small">
                                    <a href="https://status.lumen5.com/" target="_blank" className="text-white" rel="noreferrer">System Status</a> | <a href={Urls.TERMS} className="text-white">Terms of Use</a> | <a href={Urls.PRIVACY} className="text-white">Privacy Policy</a>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default PublicPageFooter;
