export function componentsJSToRgbaString(components: number[]): string {
    return 'rgba(' + components[0] +
        ',' + components[1] +
        ',' + components[2] +
        ',' + components[3] +
        ')';
}

export function int2rgb(hex: number): number[] {
    const rgb = [];
    rgb.push((hex >> 16) & 0xFF);
    rgb.push((hex >> 8) & 0xFF);
    rgb.push(hex & 0xFF);

    return rgb;
}

const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
let prefers_reduced_motion = false;
if (!mediaQuery || mediaQuery.matches) {
    prefers_reduced_motion = true;
}

export const PREFERS_REDUCED_MOTION = prefers_reduced_motion;
