import 'css/src/components/common/cookie-policy/CookieModal.scss';

import React, { useCallback } from 'react';

import { CookieToggleGroup } from './CookieToggleGroup';

import { Urls } from 'js/src/client';
import { PrimaryButton, TertiaryButton } from 'js/src/components/common/ui/Button';
import Modal, { MODAL_SIZES } from 'js/src/components/common/ui/modal';
import { COOKIE_KEYS } from 'js/src/libs/consts';
import Cookies from 'js-cookie';

export const CookieModal = ({
    showing = false,
    onCloseModal,
    storedPerformanceConsent,
    storedMarketingConsent,
}: {
    showing: boolean
    onCloseModal: VoidFunction,
    storedPerformanceConsent: boolean | undefined,
    storedMarketingConsent: boolean | undefined
}) => {
    const [isPerformanceChecked, setIsPerformanceConsent] = React.useState(storedPerformanceConsent || false);
    const [isMarketingChecked, setIsMarketingConsent] = React.useState(storedMarketingConsent || false);

    const onSave = useCallback(() => {
        // save cookie settings with redux values and close modal
        Cookies.set(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT, JSON.stringify({ storedPerformanceConsent: isPerformanceChecked, storedMarketingConsent: isMarketingChecked }), { expires: 365 });
        onCloseModal();
    }, [isMarketingChecked, isPerformanceChecked, onCloseModal]);

    const onHidden = useCallback(() => {
        onCloseModal();
    }, [onCloseModal]);

    const onMarketingToggle = useCallback(() => {
        setIsMarketingConsent(!isMarketingChecked);
    }, [isMarketingChecked]);
    const onPerformanceToggle = useCallback(() => {
        setIsPerformanceConsent(!isPerformanceChecked);
    }, [isPerformanceChecked]);

    return (
        <Modal
            className='cookie-modal'
            showing={showing}
            title='Manage your cookie settings'
            size={MODAL_SIZES.DEFAULT}
            onHidden={onHidden}
            footer={<>
                <TertiaryButton
                    text="Cancel"
                    onClick={onHidden}
                />
                <PrimaryButton
                    text={'Save'}
                    onClick={onSave}
                />
            </>}
        >
            <div className='d-flex flex-column'>
                <div>We use cookies to enhance your experience, analyze site performance, and support our service. For more details, read our <a className='text-decoration-underline' href={Urls.PRIVACY} target='_blank' rel="noreferrer">Privacy Policy</a>.</div>
                <CookieToggleGroup
                    performanceChecked={isPerformanceChecked}
                    marketingChecked={isMarketingChecked}
                    onMarketingToggle={onMarketingToggle}
                    onPerformanceToggle={onPerformanceToggle}
                />
            </div>
        </Modal>
    );
};
