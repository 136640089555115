import BootstrapModal from 'react-bootstrap/Modal';

import 'css/src/components/common/ui/modal';

import React from 'react';
import classNames from 'classnames';

import { LoadingBackdrop } from 'js/src/components/common/ui/Backdrop';
import {
    BUTTON_COLORS,
    BUTTON_PADDING,
    BUTTON_SIZES,
    BUTTON_VARIANTS,
    IconButton
} from 'js/src/components/common/ui/Button';
import Tooltip, { Placement } from 'js/src/components/common/ui/Tooltip';
import { PREFERS_REDUCED_MOTION } from 'js/src/libs/css-helpers';
import { isTesting } from 'js/src/libs/custom-utils';

export enum MODAL_SIZES {
    DEFAULT = 'default',
    SM = 'sm',
    LG = 'lg',
    XL = 'xl',
    FS = 'fs',
}

type Props = {
    showing: boolean,
    className?: string,
    onHidden?: VoidFunction,
    onShown?: VoidFunction,
    title?: string | React.ReactNode,
    size?: MODAL_SIZES,
    unsetOverflow?: boolean,
    closeable?: boolean,
    backdrop?: boolean | 'static',
    backdropClassName?: string,
    dialogClassName?: string,
    beforeShown?: VoidFunction,
    animation?: string,
    loadingContents?: boolean,
    children?: React.ReactNode,
    footer?: React.ReactNode,
    showFooterDivider?: boolean,
    noPadding?: boolean,
    stableGutters?: boolean,
    onMoveBack?: VoidFunction,
};

class Modal extends React.PureComponent<Props> {
    /*
    Use this modal class to easily add and control modals from React.

    Example usage:
        <Modal
            showing={this.state.showing}
            title="Modal Title"
            className="my-modal"
            onHidden={this._closeModal}
        >
            <h2>This is in the modal body</h2>
            <p>Add components, inputs, buttons, etc here!</p>
        </Modal>

    Any children passed to Modal will be the modal's contents.

    The showing property controls
    if the modal is showing, so likely should be stored in the caller's state.  onHidden is called
    when the modal is requested to be closed by the modal-specific functions (clicking the 'x'
    button, pressing 'escape', clicking on the backdrop) which should be handled and used to hide
    the modal.

    Modals are attached to the DOM directly and are not children of the parent React component,
    so styling must be scoped separately.  Pass in a className to use to scope each modal's
    SCSS.
    */
    constructor(props: Props) {
        super(props);

        /* function binding */
        this.onHide = this.onHide.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onHide() {
        // hide modal
        if (typeof this.props.onHidden === 'function') {
            if (this.props.showing) {
                // double-clicking on the modal backdrop can cause onHide to be called twice,
                // we check this.props.showing to avoid calling onHidden twice
                this.props.onHidden();
            }
        }
    }

    onEntered() {
        // show modal
        if (typeof this.props.onShown === 'function') {
            this.props.onShown();
        }
    }

    onEnter() {
        if (typeof this.props.beforeShown === 'function') {
            this.props.beforeShown();
        }
    }

    onShow() {
        /*
        React Bootstrap uses a transition from react-transition-groups when showing the modal:
        https://reactcommunity.org/react-transition-group/transition-group

        This controls the animation (fade and slight downward movement) when the modal is shown, and
        the opposite animation when the modal is closed.

        However, this is only used when the BootstrapModal has the `animation` prop set to true.

        We turn off this prop for modals when the user turns on PREFERS_REDUCED_MOTION, which is an
        OS-level accessibility setting.

        When the TransitionGroup is used, the order of callbacks when showing the animation are:

        - onEnter: animation is beginning
        - onShow: modal elements have been added to the DOM (not triggered by TransitionGroup)
        - onEntered: animation is ending

        However, if the TransitionGroup is not used (`BootstrapModal.animation` prop is false),
        none of the TransitionGroup callbacks are called. However, the onShow callback is called,
        so here we just use this callback to call all the required callbacks, to offer a consistent
        API for our Modal, whether the user has PREFERS_REDUCED_MOTION on or off.
        */
        if (!this.usesAnimation) {
            this.onEnter();
            this.onEntered();
        }
    }

    get usesAnimation() {
        return this.props.animation !== 'off' && !PREFERS_REDUCED_MOTION && !isTesting();
    }

    override render() {
        // set default props
        const closable = this.props.closeable ?? true;
        const loadingContents = this.props.loadingContents ?? false;
        const size = this.props.size ?? MODAL_SIZES.DEFAULT;

        let moveBackIcon;
        if (this.props.onMoveBack) {
            moveBackIcon = (
                <IconButton
                    onClick={this.props.onMoveBack}
                    icon="arrow_back_ios"
                    className="modal-moveback-button"
                    size={BUTTON_SIZES.SM}
                    color={BUTTON_COLORS.TERTIARY}
                    variant={BUTTON_VARIANTS.OUTLINE}
                    padding={BUTTON_PADDING.NONE}
                />
            );
        }

        let modalTitle;
        if (this.props.title) {
            modalTitle = (
                <BootstrapModal.Title className="font-l5 font-l5--h5 w-100" data-sharewithclosebtn={closable === true}>
                    {moveBackIcon}
                    {this.props.title}
                </BootstrapModal.Title>
            );
            if (typeof this.props.title === 'string') {
                modalTitle = (
                    <Tooltip
                        title={this.props.title}
                        placement={Placement.BOTTOM}
                        container=".modal-content"
                    >
                        {modalTitle}
                    </Tooltip>
                );
            }
        }

        let modalHeader = null;
        if (closable || modalTitle) {
            // we add a header so that we have a close button
            modalHeader = (
                <>
                    <BootstrapModal.Header className="px-l5-24 py-l5-16" closeButton={closable !== false}>
                        {modalTitle}
                    </BootstrapModal.Header>
                    {this.props.title && <hr />}
                </>
            );
        }

        // setup modal
        return (
            <BootstrapModal
                animation={this.usesAnimation}
                show={this.props.showing}
                className={classNames(this.props.className, `modal-dialog-wrapper-${size}`)}
                backdrop={this.props.backdrop}
                onHide={this.onHide}
                onEnter={this.onEnter}
                onEntered={this.onEntered}
                onShow={this.onShow}
                size={size as any}
                dialogClassName={classNames(this.props.dialogClassName, { 'overflow-unset': this.props.unsetOverflow })}
                backdropClassName={classNames(this.props.backdropClassName, `modal-backdrop-${size}`)}
            >
                {modalHeader}
                <BootstrapModal.Body className={classNames({ 'px-l5-24 py-l5-20': !this.props.noPadding, 'p-l5-0': this.props.noPadding, 'scrollbar-gutter-stable': this.props.stableGutters })}>
                    {this.props.children}
                </BootstrapModal.Body>
                {this.props.footer && (
                    <BootstrapModal.Footer className={classNames('px-l5-24 pb-l5-20', { 'border-top-0': !this.props.showFooterDivider })}>
                        {this.props.footer}
                    </BootstrapModal.Footer>
                )}
                <LoadingBackdrop className="modal-contents-loading" show={loadingContents} />
            </BootstrapModal>
        );
    }
}


export default Modal;
