import { COOKIE_KEYS } from 'js/src/libs/consts';
import Cookies from 'js-cookie';

export class CookieConsent {
    storedPerformanceConsent: boolean | undefined;
    storedMarketingConsent: boolean | undefined;

    constructor(performance: boolean | undefined, marketing: boolean | undefined) {
        this.storedPerformanceConsent = performance;
        this.storedMarketingConsent = marketing;
    }
}

export const initCookieConsent = () => {
    let cookieConsent = new CookieConsent(undefined, undefined);
    try {
        const thirdPartyCookiesConsent = Cookies.get(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT);
        if (typeof thirdPartyCookiesConsent === 'string') {
            cookieConsent = JSON.parse(thirdPartyCookiesConsent) as CookieConsent;
        }
    }
    catch {
        // eslint-disable-next-line no-console
        console.error('Unexpected error parsing cookie consent');
    }
    Cookies.set(COOKIE_KEYS.THIRD_PARTY_COOKIES_CONSENT, JSON.stringify(cookieConsent), { expires: 365 });
    window.parsed_cookie_consent_values = cookieConsent;
};
