import 'css/src/components/common/ui/ErrorBoundary';

import React, { useCallback } from 'react';
import type { Scope } from '@sentry/react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from 'js/src/components/common/ui/Button';

/**
 * React error boundary provided by Sentry
 */
export default function ErrorBoundary({ children }: { children: React.ReactNode }) {
    const onError = useCallback(() => {
        analytics.track('BlueScreenOfDeath triggered');
    }, []);

    const beforeCapture = useCallback((scope: Scope) => {
        scope.setTag('showedBSOD', 'true');
    }, []);

    return (
        <SentryErrorBoundary fallback={ErrorView} onError={onError} beforeCapture={beforeCapture}>
            {children}
        </SentryErrorBoundary>
    );
}

function ErrorView() {
    const reload = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div className="page-error-boundary">
            <i className="material-symbols-outlined mb-l5-24">
                sentiment_very_dissatisfied
            </i>
            <h2 className="fw-bold mb-l5-24">
                Oops! Something went wrong.
            </h2>
            <h5 className="text-white mb-l5-24">
                The Lumen5 team has been notified of the issue. Please reload the page and try again.
            </h5>
            <Button
                className="reload-btn"
                onClick={reload}
                size={BUTTON_SIZES.LG}
                variant={BUTTON_VARIANTS.OUTLINE}
                text="Reload the page"
            />
        </div>
    );
}
