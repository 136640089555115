import 'css/src/components/common/CookieToggleRow.scss';

import React from 'react';

import SwitchToggle from 'js/src/components/common/ui/SwitchToggle';

export const CookieToggleRow = ({
    title,
    description,
    locked,
    checked,
    onToggle,
    collapsibleData,
    isVisible,
    onCollapse,
}:{
    title: string;
    description: string;
    locked?: boolean;
    checked?: boolean;
    onToggle?: VoidFunction;
    collapsibleData?: Array<{cookieName: string, cookieDescription: string, privacyPolicy: string}>,
    isVisible?: boolean;
    onCollapse?: VoidFunction;
}) => {
    const arrowIcon = isVisible ? 'arrow_drop_down' : 'arrow_drop_up';

    return (
        <div className='d-flex flex-column mt-l5-32'>
            <div className='cookie-toggle-row d-flex justify-content-between align-items-center'>
                <div className='cookie-toggle-row__info me-l5-32 d-flex flex-column'>
                    <div className='d-flex flex-row fw-semibold'>
                        <div onClick={onCollapse} className='cursor-pointer'>
                            {title}
                            <i className='material-symbols-outlined'>{arrowIcon}</i>
                        </div>
                    </div>
                    {description}
                </div>
                <div>
                    {locked ?
                        <i className='material-symbols-outlined'>lock</i> :
                        <SwitchToggle
                            checked={checked}
                            onChange={onToggle}
                        />}
                </div>
            </div>
            {collapsibleData && collapsibleData.length > 0 && isVisible && (
                <ul className='mb-l5-0'>
                    {collapsibleData.map((data, index) => (
                        <li key={data.cookieName} className='mt-l5-16'>
                            <div className='font-l5 font-l5--subtitle-l--semibold'>{data.cookieName}</div>
                            <div className='font-l5 font-l5--subtext'>{data.cookieDescription}</div>
                            <a className='font-l5 font-l5--subtext' href={data.privacyPolicy}>{data.cookieName} privacy policy</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
